import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'
import { PostLoginRequest } from 'types/ApiRequest'
import { PostLoginResponse } from 'types/ApiResponse'

/**
 * ログインリクエスト
 * @param params - サブドメインやメールアドレスなどを含むログインパラメータ
 * @param device_certificate - デバイス証明書対応かどうか
 */
export const postLogin = async (
  params: PostLoginRequest,
  device_certificate: boolean,
) => {
  // 手違いで文字列の"true"/"false"が入ってしまうのが怖いので、念のため明示的に boolean 比較

  // NOTE: もともと使っていたlocked-auth経由し、locked-apiを叩くためのエンドポイントになっているので注意
  let endpoint = `${fullUrl()}/login`

  if (device_certificate === true) {
    // 1. まず id_ping_for_certificate にサブドメインとメールアドレスを送信
    const pingResponse = await axiosClient.post<{
      require_certificate: boolean
    }>(`${fullUrl('api')}/users/id_ping_for_certificate`, {
      team_id: params.team_id,
      email: params.session.email,
    })

    // 2. レスポンスが require_certificate === true のときだけエンドポイントを api-certificate に差し替える
    if (pingResponse.data.require_certificate === true) {
      endpoint = `${fullUrl('api-certificate')}/users/login`
    }
  }

  // 3. 最終的に決まった endpoint に対して通常のログインリクエストを送る
  const { data } = await axiosClient.post<PostLoginResponse>(endpoint, params)
  return data
}
